import RelativeTimeFormat, { Unit } from "relative-time-format";

import { sentenceCase } from "change-case";
import en from "relative-time-format/locale/en";

RelativeTimeFormat.addLocale(en);

export type Duration = Partial<FullDuration>;
export type FullDuration = Record<DurationLabel, number>;
export type DurationLabel =
  | "year"
  | "month"
  | "week"
  | "day"
  | "hour"
  | "minute"
  | "second"
  | "millisecond";

const defaultDuration: FullDuration = {
  year: 0,
  month: 0,
  week: 0,
  day: 0,
  hour: 0,
  minute: 0,
  second: 0,
  millisecond: 0,
};

const units: Record<DurationLabel, number> = {
  year: 24 * 60 * 60 * 1000 * 365,
  month: (24 * 60 * 60 * 1000 * 365) / 12,
  week: 24 * 60 * 60 * 1000 * 7,
  day: 24 * 60 * 60 * 1000,
  hour: 60 * 60 * 1000,
  minute: 60 * 1000,
  second: 1000,
  millisecond: 1,
};

export const getMillisecondsFromDuration = (duration: Duration): number => {
  const durationToConvert: FullDuration = {
    ...defaultDuration,
    ...duration,
  };

  const milliseconds =
    durationToConvert.year * units.year +
    durationToConvert.month * units.month +
    durationToConvert.week * units.week +
    durationToConvert.day * units.day +
    durationToConvert.hour * units.hour +
    durationToConvert.minute * units.minute +
    durationToConvert.second * units.second +
    durationToConvert.millisecond * units.millisecond;

  return milliseconds;
};

const getDateDifference = (
  firstDate: Date,
  secondDate: Date
): { value: number; unit: Unit } | undefined => {
  const elapsed = firstDate.getTime() - secondDate.getTime();
  for (const u in units) {
    if (Math.abs(elapsed) >= units[u as keyof typeof units] || u === "second") {
      return {
        value: Math.round(elapsed / units[u as keyof typeof units]),
        unit: u as Unit,
      };
    }
  }
};

export const getRelativeDateString = (
  relativeDate: Date,
  compareToDate?: Date
) => {
  const compareDate = compareToDate || new Date();
  const difference = getDateDifference(relativeDate, compareDate);
  if (!difference) return "";
  const formatter = new RelativeTimeFormat("en", { numeric: "auto" });
  return sentenceCase(formatter.format(difference.value, difference.unit));
};

export const formatDate = (value: Date, format: Intl.DateTimeFormatOptions) => {
  return new Intl.DateTimeFormat("en-GB", format).format(value);
};

export const SHORT_DATE_FORMAT_OPTIONS: Intl.DateTimeFormatOptions = {
  weekday: "short",
  month: "short",
  day: "2-digit",
};

export const formatToShortDate = (date: Date) =>
  formatDate(date, SHORT_DATE_FORMAT_OPTIONS);

export const formatToShortMonthAndYear = (date: Date) =>
  formatDate(date, { month: "short", year: "numeric" });

export const formatToShortDayAndMonth = (date: Date) =>
  formatDate(date, { day: "2-digit", month: "short", year: "numeric" });

export const startOfDay = (date: Date) => {
  const d = new Date(date.valueOf());
  d.setHours(0, 0, 0, 0);
  return d;
};

export const startOfMonth = (date: Date) => {
  const d = new Date(date);
  d.setDate(1);
  d.setHours(0, 0, 0, 0);
  return d;
};

export const formatLongDateWithoutWeekday = (date: Date) => {
  const options: Intl.DateTimeFormatOptions = {
    year: "numeric",
    month: "long",
    day: "numeric",
  };
  return new Intl.DateTimeFormat("en-US", options).format(date);
};

const getCalendarDayEpoch = (date: Date) =>
  Date.UTC(date.getFullYear(), date.getMonth(), date.getDate());

export const getDateDifferenceInWholeDays = (
  earlierDate: Date,
  laterDate: Date
) => {
  return Math.floor(
    (getCalendarDayEpoch(laterDate) - getCalendarDayEpoch(earlierDate)) /
      (1000 * 60 * 60 * 24)
  );
};
