import { Card, CardContent, Divider, Stack, Typography } from "@mui/material";
import React from "react";
import { ExerciseBlock } from "../../../store/workout/types";
import { isBlockSuperset } from "../../pages/WorkoutBuilder/utils";
import { Entry } from "./Entry";
import { ExerciseBlockChips } from "./ExerciseBlockChips";

interface Props {
  block: ExerciseBlock;
}

export const Block: React.FC<Props> = ({ block }) => {
  const isSuperset = isBlockSuperset(block);

  return (
    <Card
      elevation={1}
      sx={{ backgroundColor: isSuperset ? "primary.mid" : undefined }}
    >
      <Stack p={1} direction="row-reverse">
        <Stack direction="row" gap={1}>
          <ExerciseBlockChips block={block} showMuscleGroupChips />
        </Stack>
      </Stack>
      <Divider />
      <CardContent>
        <Stack gap={1}>
          {block.entrys.map((entry) => (
            <Entry key={entry.id} entry={entry} />
          ))}
          {!block.entrys.length && (
            <Typography variant="body1">No Entrys</Typography>
          )}
        </Stack>
      </CardContent>
    </Card>
  );
};
