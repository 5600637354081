import { useCallback, useMemo, useState } from "react";
import { ExerciseVideoCardProps } from ".";
import { useModalState } from "../../../../../hooks/useModalState";
import { getMediaUrl } from "../../../../common/MuxPlayer/MuxPlayer.utils";
import { useExerciseAutocomplete } from "../../../WorkoutBuilder/ExerciseAutocomplete/useExerciseAutocomplete";

export const useExerciseVideoCard = ({
  video,
  exerciseName,
  exerciseId,
  onDelete,
  onSetDefault,
  disableDelete = false,
  selectedExercise,
  setSelectedExercise,
  switchVideoExerciseSuccessToast,
  switchVideoExerciseErrorToast,
  handleSwitchVideoExercise,
  isSwitchVideoExerciseLoading,
}: ExerciseVideoCardProps) => {
  const { muxVideo } = video;
  const mediaUrl = getMediaUrl(video);

  const videoPlayerModal = useModalState(false);
  const deleteConfirmDialog = useModalState(false);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const isMenuOpen = Boolean(anchorEl);
  const openMenu = (event: React.MouseEvent<HTMLButtonElement>) =>
    setAnchorEl(event.currentTarget);
  const closeMenu = () => setAnchorEl(null);

  const closeMenuWithCallback = (callback: () => void) => () => {
    closeMenu();
    callback();
  };
  const closeConfirmWithCallback = (callback: () => void) => () => {
    deleteConfirmDialog.hide();
    callback();
  };

  const switchExerciseDialog = useModalState(false);
  const handleSwitchVideoExerciseSubmit = useCallback(async () => {
    handleSwitchVideoExercise(video.id);
    switchExerciseDialog.hide();
  }, [handleSwitchVideoExercise, switchExerciseDialog, video.id]);

  const exerciseAutocomplete = useExerciseAutocomplete();
  const filteredData = useMemo(
    () =>
      exerciseAutocomplete.exerciseSearchResultsResource.data?.filter(
        (item) => {
          return item.id !== exerciseId;
        }
      ),
    [exerciseAutocomplete.exerciseSearchResultsResource.data, exerciseId]
  );

  const isDownloadVideoDisabled = useMemo(() => {
    return !muxVideo || !muxVideo.staticRenditionReady;
  }, [muxVideo]);

  const downloadVideo = useCallback(async () => {
    if (muxVideo) {
      const url = `https://stream.mux.com/${muxVideo.playbackId}/capped-1080p.mp4?token=${muxVideo.videoToken}`;
      try {
        const response = await fetch(url);
        const blob = await response.blob();
        const blobUrl = URL.createObjectURL(blob);

        const anchor = document.createElement("a");
        anchor.href = blobUrl;
        anchor.download = `${exerciseName}-${muxVideo.title}`;
        document.body.appendChild(anchor);
        anchor.click();

        URL.revokeObjectURL(blobUrl);
        document.body.removeChild(anchor);
      } catch (error) {
        console.error("Failed to download video:", error);
      }
    }
  }, [exerciseName, muxVideo]);

  return {
    video,
    muxVideo,
    exerciseName,
    mediaUrl,
    videoPlayerModal,
    deleteConfirmDialog,
    anchorEl,
    isMenuOpen,
    openMenu,
    closeMenu,
    closeMenuWithCallback,
    closeConfirmWithCallback,
    onDelete,
    onSetDefault,
    disableDelete,
    isDownloadVideoDisabled,
    handleSwitchVideoExerciseSubmit,
    isSwitchVideoExerciseLoading,
    switchExerciseDialog,
    selectedExercise,
    setSelectedExercise,
    switchVideoExerciseErrorToast,
    switchVideoExerciseSuccessToast,
    ...exerciseAutocomplete,
    exerciseSearchResultsResource: {
      ...exerciseAutocomplete.exerciseSearchResultsResource,
      data: filteredData,
    },
    downloadVideo,
  };
};
