import { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useErrorMessageState } from "../../../hooks/useErrorMessageState";
import { useStore } from "../../../store";
import { GetWorkoutPlan } from "../../../store/workout-plan/actions";
import { selectCurrentWorkoutPlanResource } from "../../../store/workout-plan/selectors";
import {
  GetExpandedScheduleItem,
  PutWorkoutFromScheduleItemID,
} from "../../../store/workout/actions";
import {
  selectRetrieveWorkoutResource,
  selectUpdateWorkoutResource,
} from "../../../store/workout/selectors";
import {
  ExpandedScheduleItem,
  ExpandedWorkout,
} from "../../../store/workout/types";
import { generateEmptyWorkoutSection } from "../WorkoutBuilder/utils";

export const useWorkoutPlanDayPage = (
  initialExpandedScheduleItem?: ExpandedScheduleItem | null
) => {
  const { scheduleItemId: scheduleItemIdString, workoutPlanId } = useParams();
  const navigate = useNavigate();
  const navigateToPlan = () => {
    navigate(`/workout-plans/edit/${workoutPlanId}`);
  };

  const schduleItemId = scheduleItemIdString!;

  const [select, dispatch] = useStore();

  const [expandedScheduleItem, setExpandedScheduleItem] =
    useState<ExpandedScheduleItem | null>(initialExpandedScheduleItem ?? null);

  const currentWorkoutPlanResource = select(selectCurrentWorkoutPlanResource);

  const getExpandedWorkoutFromPlaylistDayId = useCallback(async () => {
    if (schduleItemId) {
      const expandedScheduleItem = await GetExpandedScheduleItem(
        dispatch,
        schduleItemId
      );
      if (expandedScheduleItem?.workout.sections.length === 0) {
        expandedScheduleItem &&
          setExpandedScheduleItem({
            ...expandedScheduleItem,
            workout: {
              ...expandedScheduleItem.workout,
              sections: [generateEmptyWorkoutSection()],
            },
          });
      } else {
        expandedScheduleItem && setExpandedScheduleItem(expandedScheduleItem);
      }
    }
  }, [dispatch, schduleItemId]);

  const retrieveWorkoutResource = select(selectRetrieveWorkoutResource);
  const [isGetWorkoutError, hideGetWorkoutError] = useErrorMessageState(
    retrieveWorkoutResource.isError
  );

  const updateWorkoutName = useCallback(
    async (name: string) => {
      if (expandedScheduleItem) {
        await PutWorkoutFromScheduleItemID(dispatch, expandedScheduleItem.id, {
          name,
        });
      }
    },
    [dispatch, expandedScheduleItem]
  );

  const updateWorkoutDescription = useCallback(
    async (description: string) => {
      if (expandedScheduleItem) {
        await PutWorkoutFromScheduleItemID(dispatch, expandedScheduleItem.id, {
          description,
        });
      }
    },
    [dispatch, expandedScheduleItem]
  );

  const saveWorkout = useCallback(
    async (expandedWorkout: ExpandedWorkout) => {
      if (expandedScheduleItem) {
        const updatedExpandedScheduleItem = await PutWorkoutFromScheduleItemID(
          dispatch,
          expandedScheduleItem.id,
          { ...expandedWorkout }
        );
        if (updatedExpandedScheduleItem) {
          setExpandedScheduleItem(updatedExpandedScheduleItem);
          return updatedExpandedScheduleItem.workout;
        }
      }
      return undefined;
    },
    [dispatch, expandedScheduleItem]
  );

  const updateWorkoutResource = select(selectUpdateWorkoutResource);

  useEffect(() => {
    getExpandedWorkoutFromPlaylistDayId().then(() => {
      if (
        !currentWorkoutPlanResource.data ||
        currentWorkoutPlanResource.data.id !== workoutPlanId!
      ) {
        GetWorkoutPlan(dispatch, workoutPlanId!);
      }
    });
  }, [
    currentWorkoutPlanResource.data,
    dispatch,
    getExpandedWorkoutFromPlaylistDayId,
    workoutPlanId,
  ]);

  return {
    expandedWorkout: expandedScheduleItem?.workout,
    isGetWorkoutError,
    hideGetWorkoutError,
    updateWorkoutName,
    updateWorkoutDescription,
    saveWorkout,
    isUpdating: updateWorkoutResource.isLoading,
    isUpdateError: updateWorkoutResource.isError,
    navigateToPlan,
    weekAndDay: expandedScheduleItem?.weekAndDay,
    workoutPlanName: currentWorkoutPlanResource.data?.name,
  };
};
