import { CreatorExercise, CreatorExerciseAPI } from "../exercises/types";
import {
  APICallState,
  PaginatedResource,
  Resource,
  createDefaultPaginatedResource,
  createDefaultResource,
  successLoadingErrorState,
} from "../types";

export interface WorkoutAPI {
  id: string;
  name: string;
  description: string;
  is_favourite: boolean;
  duration_mins: number;
}

export interface Workout {
  id: string;
  name: string;
  description: string;
  isFavourite: boolean;
  durationMins: number;
}

export interface WorkoutState {
  workouts: PaginatedResource<Workout>;
  currentWorkout: ExpandedWorkout | null;
  create: APICallState;
  allWorkouts: Resource<Workout[]>;
  retrieve: APICallState;
  update: APICallState;
  destroy: APICallState;
}

export interface ExpandedWorkoutAPI extends WorkoutAPI {
  sections: WorkoutSectionAPI[];
}

export interface WorkoutSectionAPI {
  id: string;
  blocks: ExerciseBlockAPI[];
}

export interface ExerciseBlockAPI {
  id: string;
  index: number;
  entrys: ExerciseEntryAPI[];
}

export interface ExerciseEntryAPI {
  id: string;
  index: number;
  notes: string;
  exercise: CreatorExerciseAPI;
  sets: ExerciseSetAPI[];
  video_id: number | null;
}

export interface ExerciseSetAPI {
  id: string;
  number: number;
  values: ExerciseParameterValueAPI[];
}

export interface ExerciseParameterValueAPI {
  id: string;
  parameter: ExerciseParameterAPI;
  value: number;
}

export interface ExerciseParameterAPI {
  id: string;
  name: string;
  unit: string;
}

export interface ExpandedWorkout extends Workout {
  sections: WorkoutSection[];
}

export interface WorkoutSection {
  id: string;
  blocks: ExerciseBlock[];
}

export interface ExerciseBlock {
  id: string;
  entrys: ExerciseEntry[];
}

export interface ExerciseEntry {
  id: string;
  exercise: CreatorExercise;
  notes: string;
  sets: ExerciseSet[];
  videoId: number | null;
}

export interface ExerciseSet {
  id: string;
  values: ExerciseParameterValue[];
}

export interface ExerciseParameterValue {
  id: string;
  parameter: ExerciseParameter;
  value: number;
}

export enum ExerciseParameterID {
  REPS = "343ebe89-8a00-4def-be14-d1cb547b3fe8",
  REST = "2a8c05d7-f30f-4aae-a1f9-3e7cde71b3d9",
  TIME = "646b042e-1c65-4d35-8e34-3f72315736cb",
  WEIGHT = "d122537f-1a71-4cc4-8b45-35ba3f0832d4",
}

export enum ExerciseParameterName {
  REPS = "Reps",
  REST = "Rest",
  TIME = "Time",
  WEIGHT = "Weight",
}

export interface ExerciseParameter {
  id: ExerciseParameterID;
  name: ExerciseParameterName;
  unit: string;
}

export interface ExpandedScheduleItemAPI {
  id: string;
  day: number;
  workout: ExpandedWorkoutAPI;
}

export interface ExpandedScheduleItem {
  id: string;
  weekAndDay: WeekAndDay;
  workout: ExpandedWorkout;
}

export interface WeekAndDay {
  week: number;
  dayOfWeek: number;
  dayNumber: number;
}

export interface ScheduleItem {
  id: string;
  weekAndDay: WeekAndDay;
  workout: Workout | null;
}

export interface ScheduleItemAPI {
  id: string;
  day: number;
  workout: WorkoutAPI | null;
}

export type Schedule = ScheduleItem[];
export type ScheduleAPI = ScheduleItemAPI[];

export const defaultWorkoutState: WorkoutState = {
  workouts: createDefaultPaginatedResource(),
  currentWorkout: null,
  allWorkouts: createDefaultResource(),
  create: successLoadingErrorState,
  retrieve: successLoadingErrorState,
  update: successLoadingErrorState,
  destroy: successLoadingErrorState,
};
