import {
  transformCreatorExerciseFromAPI,
  transformCreatorExerciseToAPI,
} from "../exercises/transformer";
import { Exercise, ExerciseAPI } from "../workout-builder/types";
import { transformDayToDayOfWeek } from "../workout-plan/transformer";
import {
  ExerciseBlock,
  ExerciseBlockAPI,
  ExerciseEntry,
  ExerciseEntryAPI,
  ExerciseParameter,
  ExerciseParameterAPI,
  ExerciseParameterID,
  ExerciseParameterName,
  ExerciseParameterValue,
  ExerciseParameterValueAPI,
  ExerciseSet,
  ExerciseSetAPI,
  ExpandedScheduleItem,
  ExpandedScheduleItemAPI,
  ExpandedWorkout,
  ExpandedWorkoutAPI,
  Workout,
  WorkoutAPI,
  WorkoutSection,
  WorkoutSectionAPI,
} from "./types";

export const transformAllWorkoutsFromAPI = (api: WorkoutAPI[]) =>
  api.map(transformWorkoutFromAPI);

export const transformWorkoutFromAPI = (api: WorkoutAPI): Workout => ({
  id: api.id,
  name: api.name,
  description: api.description,
  isFavourite: api.is_favourite,
  durationMins: api.duration_mins,
});

export const transformWorkoutToAPI = (workout: Workout): WorkoutAPI => ({
  id: workout.id,
  name: workout.name,
  description: workout.description,
  is_favourite: workout.isFavourite,
  duration_mins: workout.durationMins,
});

export const transformStringIntoExerciseParameterID = (
  string: string
): ExerciseParameterID => {
  const parameterID = Object.values(ExerciseParameterID).find(
    (value) => value === string
  );
  if (parameterID) return parameterID;
  throw new Error(`Unknown parameter id ${string}`);
};

const transformStringIntoExerciseParameterName = (
  string: string
): ExerciseParameterName => {
  const parameterName = Object.values(ExerciseParameterName).find(
    (value) => value === string
  );
  if (parameterName) return parameterName;
  throw new Error(`Unknown parameter name ${string}`);
};

export const transformExerciseParameterFromAPI = (
  param: ExerciseParameterAPI
): ExerciseParameter => ({
  id: transformStringIntoExerciseParameterID(param.id),
  name: transformStringIntoExerciseParameterName(param.name),
  unit: param.unit,
});

export const transformExerciseParameterValueFromAPI = (
  api: ExerciseParameterValueAPI
): ExerciseParameterValue => ({
  id: api.id,
  parameter: transformExerciseParameterFromAPI(api.parameter),
  value: api.value,
});

export const transformExerciseSetFromAPI = (
  set: ExerciseSetAPI
): ExerciseSet => ({
  id: set.id,
  values: set.values.map(transformExerciseParameterValueFromAPI),
});

export const transformExerciseEntryFromAPI = (
  entry: ExerciseEntryAPI
): ExerciseEntry => ({
  id: entry.id,
  exercise: transformCreatorExerciseFromAPI(entry.exercise),
  notes: entry.notes,
  sets: entry.sets.map(transformExerciseSetFromAPI),
  videoId: entry.video_id,
});

export const transformExerciseBlockFromAPI = (
  block: ExerciseBlockAPI
): ExerciseBlock => ({
  id: block.id,
  entrys: block.entrys.map(transformExerciseEntryFromAPI),
});

export const transformWorkoutSectionFromAPI = (
  section: WorkoutSectionAPI
): WorkoutSection => ({
  id: section.id,
  blocks: section.blocks.map(transformExerciseBlockFromAPI),
});

export const transformExpandedWorkoutFromAPI = (
  workout: ExpandedWorkoutAPI
): ExpandedWorkout => ({
  id: workout.id,
  name: workout.name,
  description: workout.description,
  isFavourite: workout.is_favourite,
  sections: workout.sections?.map(transformWorkoutSectionFromAPI) ?? undefined,
  durationMins: workout.duration_mins,
});

export const transformSetValueToAPI = (
  value: ExerciseParameterValue
): ExerciseParameterValueAPI => ({
  id: value.id,
  parameter: value.parameter,
  value: value.value,
});

export const transformExerciseSetToAPI = (
  set: ExerciseSet,
  index: number
): ExerciseSetAPI => ({
  id: set.id,
  number: index + 1,
  values: set.values.map(transformSetValueToAPI),
});

export const transformExerciseToAPI = (exercise: Exercise): ExerciseAPI => ({
  id: exercise.id,
  name: exercise.name,
  major_muscle_group: exercise.muscleGroup,
});

export const transformExerciseEntryToAPI = (
  entry: ExerciseEntry,
  index: number
): ExerciseEntryAPI => ({
  id: entry.id,
  exercise: transformCreatorExerciseToAPI(entry.exercise),
  index,
  // To ensure we're not saving the same note twice
  notes: entry.notes === entry.exercise.defaultNote ? "" : entry.notes,
  sets: entry.sets.map(transformExerciseSetToAPI),
  video_id: entry.videoId,
});

export const transformExerciseBlockToAPI = (
  block: ExerciseBlock,
  index: number
): ExerciseBlockAPI => ({
  id: block.id,
  index,
  entrys: block.entrys.map(transformExerciseEntryToAPI),
});

export const transformWorkoutSectionToAPI = (
  section: WorkoutSection
): WorkoutSectionAPI => ({
  id: section.id,
  blocks: section.blocks.map(transformExerciseBlockToAPI),
});

export const transformExpandedWorkoutToAPI = (
  workout: Partial<ExpandedWorkout>
): Partial<ExpandedWorkoutAPI> => ({
  id: workout.id,
  name: workout.name,
  description: workout.description,
  duration_mins: workout.durationMins,
  is_favourite: workout.isFavourite,
  sections: workout.sections?.map(transformWorkoutSectionToAPI) ?? undefined,
});

export const transformExpandedScheduleItemFromAPI = (
  api: ExpandedScheduleItemAPI
): ExpandedScheduleItem => ({
  id: api.id,
  weekAndDay: transformDayToDayOfWeek(api.day),
  workout: transformExpandedWorkoutFromAPI(api.workout),
});
