import { Edit } from "@mui/icons-material";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import React from "react";
import { Workout } from "../../../store/workout/types";
import { formatWorkoutDuration } from "../WorkoutBuilder/utils";
import { WorkoutViewer } from "./WorkoutViewer";
import { AccessTime } from "@mui/icons-material";

interface Props {
  workout: Workout;
  isOpen: boolean;
  close: () => void;
  canEdit: boolean;
  onEdit?: () => void;
  disabledEditTooltipText?: string;
  isNonEditable?: boolean;
  weekIndex?: number;
  dayIndex?: number;
}

export const WorkoutViewDialog: React.FC<Props> = ({
  workout,
  isOpen,
  close,
  canEdit,
  onEdit,
  disabledEditTooltipText: editTooltipText,
  weekIndex,
  dayIndex,
}) => {
  return (
    <Dialog open={isOpen} onClose={close} fullWidth maxWidth="lg">
      <Box
        display="flex"
        flexDirection={"row"}
        justifyContent={"space-between"}
        sx={{ bgcolor: "background.default" }}
      >
        <Stack>
          <DialogTitle>{workout.name}</DialogTitle>
          <Box pl={3} pb={3}>
            {workout.description ? (
              <DialogContentText>{workout.description}</DialogContentText>
            ) : (
              <Typography variant="body1" sx={{ fontStyle: "italic" }}>
                No Description
              </Typography>
            )}
          </Box>
        </Stack>
        <Stack pr={3} pt={3} gap={1}>
          {weekIndex !== undefined && dayIndex !== undefined && (
            <Typography
              textAlign="right"
              variant="body2"
              fontWeight={700}
            >{`Week ${weekIndex + 1} Day ${dayIndex + 1}`}</Typography>
          )}
          {!!workout.durationMins && (
            <Tooltip title="Guideline time to complete workout including exercise setup time">
              <Box
                gap={1}
                display={"flex"}
                flexDirection={"row"}
                alignItems={"center"}
              >
                <Typography
                  variant="body2"
                  textAlign="right"
                  sx={(theme) => ({ color: theme.palette.text.secondary })}
                >
                  {`Estimated time: ${formatWorkoutDuration(
                    workout.durationMins
                  )}`}
                </Typography>
                <AccessTime
                  fontSize="small"
                  sx={(theme) => ({ color: theme.palette.text.secondary })}
                />
              </Box>
            </Tooltip>
          )}
        </Stack>
      </Box>
      <DialogContent dividers sx={{ bgcolor: "background.default" }}>
        <WorkoutViewer workoutId={workout.id} />
      </DialogContent>
      <DialogActions sx={{ bgcolor: "background.default" }}>
        <Button onClick={close}>Cancel</Button>
        {onEdit && (
          <Tooltip
            title={
              canEdit
                ? ""
                : editTooltipText ||
                  "Save the workout plan before editing this workout"
            }
          >
            <span>
              <Button
                variant="contained"
                disabled={!canEdit}
                onClick={onEdit}
                startIcon={<Edit />}
              >
                Edit
              </Button>
            </span>
          </Tooltip>
        )}
      </DialogActions>
    </Dialog>
  );
};
