import { CreatorExercise } from "../../../store/exercises/types";
import {
  ExerciseBlock,
  ExerciseEntry,
  ExerciseParameter,
  ExerciseParameterID,
  ExerciseParameterValue,
  ExerciseSet,
  ExpandedWorkout,
  WorkoutSection,
} from "../../../store/workout/types";
import { roundToNearestN, roundUpToNearestN } from "../../../util/number";
import { pluralize } from "../../../util/string";
import { randomUUID } from "../../../util/uuid";
import {
  REPS_EXERCISE_PARAMETER,
  REST_EXERCISE_PARAMETER,
  TIME_EXERCISE_PARAMETER,
  createDefaultRepsParameterValue,
  createDefaultRestParameterValue,
} from "./fixtures";
import {
  NoIdExerciseBlock,
  NoIdExerciseEntry,
  NoIdExerciseParameterValue,
  NoIdExerciseSet,
  NoIdWorkoutSection,
} from "./types";

const SECONDS_PER_REP = 3;
const SECONDS_PER_SET_DEAD_TIME = 0;
const SECONDS_PER_ENTRY_DEAD_TIME = 0;
const SECONDS_PER_BLOCK_DEAD_TIME = 150;
const SECONDS_PER_SECTION_DEAD_TIME = 0;
const WORKOUT_LEVEL_DEAD_TIME_SECONDS = 0;

const removeRestFromExerciseEntry = (entry: ExerciseEntry): ExerciseEntry => {
  return {
    ...entry,
    sets: entry.sets.map((set) => ({
      ...set,
      values: set.values.filter(
        (value) => value.parameter.id !== REST_EXERCISE_PARAMETER.id
      ),
    })),
  };
};

export const createSupersetBlock = (entrys: ExerciseEntry[]) => {
  const entrysWithRestRemoved = entrys.map((entry, index, entrys) => {
    if (index === entrys.length - 1) {
      return entry;
    }
    return removeRestFromExerciseEntry(entry);
  });

  return generateExerciseBlockWithEntrys(entrysWithRestRemoved);
};

export const generateEmptyWorkoutSection = (): WorkoutSection => ({
  id: randomUUID(),
  blocks: [],
});

export const generateExerciseBlockWithEntrys = (
  exerciseEntrys: ExerciseEntry[]
): ExerciseBlock => {
  return {
    id: randomUUID(),
    entrys: exerciseEntrys,
  };
};
export const generateDefaultExerciseBlockWithExercise = (
  exercise: CreatorExercise
) => {
  const createExerciseEntry = () => ({
    id: randomUUID(),
    exercise,
    notes: "",
    videoId: null,
    sets: [
      {
        id: randomUUID(),
        values: [
          createDefaultRepsParameterValue(),
          createDefaultRestParameterValue(),
        ],
      },
    ],
  });

  const entries = exercise.isUnilateral
    ? [createExerciseEntry(), createExerciseEntry()]
    : [createExerciseEntry()];

  return generateExerciseBlockWithEntrys(entries);
};

export const getExerciseParameterFromExerciseEntry = (
  exerciseEntry: ExerciseEntry
): ExerciseParameter => {
  const exerciseEntryParameters = exerciseEntry.sets
    .map((set) => set.values.map((value) => value.parameter))
    .flat();
  const exerciseEntryParameterNames = exerciseEntryParameters.map(
    ({ name }) => name
  );
  const parameterNamesArray = Array.from(new Set(exerciseEntryParameterNames));

  return parameterNamesArray.includes(REPS_EXERCISE_PARAMETER.name)
    ? REPS_EXERCISE_PARAMETER
    : TIME_EXERCISE_PARAMETER;
};

const stripIdFromValue = ({
  parameter,
  value,
}: ExerciseParameterValue): NoIdExerciseParameterValue => ({
  parameter,
  value,
});

const stripIdFromSet = (set: ExerciseSet): NoIdExerciseSet => ({
  values: set.values.map(stripIdFromValue),
});

const stripIdFromEntry = ({
  exercise,
  sets,
  notes,
  videoId,
}: ExerciseEntry): NoIdExerciseEntry => ({
  exercise: {
    id: exercise.id,
    name: exercise.name,
    muscleGroup: exercise.muscleGroup,
  },
  sets: sets.map(stripIdFromSet),
  videoId,
  notes: notes === "" ? exercise.defaultNote || "" : notes,
});

const stripIdFromBlock = (block: ExerciseBlock): NoIdExerciseBlock => ({
  entrys: block.entrys.map(stripIdFromEntry),
});
const stripIdFromSection = (section: WorkoutSection): NoIdWorkoutSection => ({
  blocks: section.blocks.map(stripIdFromBlock),
});

export const stripIdsFromWorkout = (
  sections: WorkoutSection[]
): NoIdWorkoutSection[] => sections.map(stripIdFromSection);

export const isBlockSuperset = (block: ExerciseBlock | NoIdExerciseBlock) => {
  return block.entrys.length > 1;
};

export const getEntryNotes = (entry: ExerciseEntry): string => {
  const entrySpecificNotes = entry.notes;
  const exerciseSpecificInstruction = entry.exercise.defaultNote;
  if (entrySpecificNotes !== "") {
    return entrySpecificNotes;
  }
  if (
    exerciseSpecificInstruction !== null &&
    exerciseSpecificInstruction !== ""
  ) {
    return exerciseSpecificInstruction;
  }
  return "";
};

export const getRestValuesFromEntry = (entry: ExerciseEntry) => {
  return entry.sets.flatMap(
    (set) =>
      set.values.find(
        (value) => value.parameter.id === REST_EXERCISE_PARAMETER.id
      )!
  );
};

export const removeRestValuesFromEntry = (entry: ExerciseEntry) => {
  const removedValues: ExerciseParameterValue[] = [];

  entry.sets = entry.sets.map((set) => {
    set.values = set.values.filter((value) => {
      if (value.parameter.id === REST_EXERCISE_PARAMETER.id) {
        removedValues.push(value);
        return false;
      }
      return true;
    });
    return set;
  });

  return removedValues;
};

interface CalculateIfWorkoutHasChangedArgs {
  initialExpandedWorkout: ExpandedWorkout;
  currentExpandedWorkout: ExpandedWorkout;
}

export const calculateIfWorkoutHasChanged = ({
  initialExpandedWorkout,
  currentExpandedWorkout,
}: CalculateIfWorkoutHasChangedArgs) => {
  const initial = stripIdsFromWorkout(initialExpandedWorkout.sections);
  const current = stripIdsFromWorkout(currentExpandedWorkout.sections);

  return JSON.stringify(initial) !== JSON.stringify(current);
};

export const calculateWorkoutDuration = (
  workoutSections: ExpandedWorkout["sections"]
): number => {
  let totalWorkoutDurationSeconds = 0;

  totalWorkoutDurationSeconds = workoutSections.reduce(
    (sectionAcc, section) => {
      const numberOfBlocks = section.blocks.length;
      const sectionDuration = section.blocks.reduce(
        (blockAcc, block, index) => {
          const blockDuration = block.entrys.reduce((entryAcc, entry) => {
            const entryDuration = entry.sets.reduce((setAcc, set) => {
              let estimatedSetDuration = 0;

              const repsParam = set.values.find(
                (v) => v.parameter.id === ExerciseParameterID.REPS
              );
              const timeParam = set.values.find(
                (v) => v.parameter.id === ExerciseParameterID.TIME
              );
              const restParam = set.values.find(
                (v) => v.parameter.id === ExerciseParameterID.REST
              );

              if (repsParam) {
                estimatedSetDuration += repsParam.value * SECONDS_PER_REP;
              } else if (timeParam) {
                estimatedSetDuration += timeParam.value;
              } else {
                console.error("Set has no reps or time value");
              }

              const restValue = restParam ? restParam.value : 0;
              estimatedSetDuration += restValue + SECONDS_PER_SET_DEAD_TIME;
              return setAcc + estimatedSetDuration;
            }, 0);

            return entryAcc + entryDuration + SECONDS_PER_ENTRY_DEAD_TIME;
          }, 0);

          const isLastBlock = index === numberOfBlocks - 1;

          return (
            blockAcc +
            blockDuration +
            (isLastBlock ? 0 : SECONDS_PER_BLOCK_DEAD_TIME)
          );
        },
        0
      );

      return sectionAcc + sectionDuration + SECONDS_PER_SECTION_DEAD_TIME;
    },
    WORKOUT_LEVEL_DEAD_TIME_SECONDS
  );

  return roundToNearestN(totalWorkoutDurationSeconds / 60, 1);
};

export const formatWorkoutDuration = (workoutDuration: number) => {
  if (workoutDuration < 5) {
    return `${roundUpToNearestN(workoutDuration, 1)} ${pluralize(
      "min",
      workoutDuration
    )}`;
  }

  const duration = roundToNearestN(workoutDuration, 5);

  if (duration === 60) {
    return "60 mins";
  }

  const mins = duration % 60;
  const hours = Math.floor(duration / 60);

  if (hours > 0 && mins > 0) {
    return `${hours} ${pluralize("hr", hours)} ${mins} ${pluralize(
      "min",
      mins
    )}`;
  }

  if (hours > 0) {
    return `${hours} ${pluralize("hr", hours)}`;
  }

  return `${mins} ${pluralize("min", mins)}`;
};
