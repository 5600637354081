import AccessTime from "@mui/icons-material/AccessTime";
import { Box, Chip, TableCell, Typography } from "@mui/material";
import React from "react";
import { ScheduleItem } from "../../../../store/workout/types";
import { formatWorkoutDuration } from "../../WorkoutBuilder/utils";
import { WorkoutViewDialog } from "../../WorkoutPlanBuilder/WorkoutViewerDialog";
import { WorkoutRow } from "./styles";
import { useWorkoutPlanParam } from "./useWorkoutPlanParam";

interface WorkoutAndScheduleProps {
  scheduleItem: ScheduleItem;
  canEdit: boolean;
  onClickEdit: () => void;
}

export const WorkoutAndSchedule: React.FC<WorkoutAndScheduleProps> = ({
  scheduleItem,
  onClickEdit,
  canEdit,
}) => {
  const { id } = scheduleItem;
  const [isPeekingWorkout, setIsPeekingWorkout] = useWorkoutPlanParam(id);

  return (
    <>
      <WorkoutRow
        $isRest={!scheduleItem.workout}
        onClick={() => setIsPeekingWorkout(true)}
        sx={{
          position: "relative",
          cursor: !scheduleItem.workout ? undefined : "pointer",
          backgroundColor: !scheduleItem.workout ? undefined : "primary.xlight",
          "&:hover": {
            backgroundColor: !scheduleItem.workout
              ? undefined
              : "primary.hover",
          },
        }}
      >
        <TableCell>Day {scheduleItem.weekAndDay.dayOfWeek}</TableCell>
        <TableCell>{scheduleItem.workout?.name || "Rest Day"}</TableCell>
        <TableCell align="center">
          {!!scheduleItem.workout && !!scheduleItem.workout?.durationMins && (
            <Box flexDirection="row" display="flex" alignItems="center" gap={1}>
              <Typography
                variant="body2"
                component={"span"}
                noWrap
                sx={(theme) => ({ color: theme.palette.text.secondary })}
              >
                {formatWorkoutDuration(scheduleItem.workout?.durationMins)}
              </Typography>
              <AccessTime
                fontSize="small"
                sx={(theme) => ({ color: theme.palette.text.secondary })}
              />
            </Box>
          )}
        </TableCell>
        {scheduleItem.weekAndDay.dayOfWeek === 1 && (
          <Box
            position="absolute"
            left="50%"
            component={"td"}
            sx={{ borderTop: "unset !important" }}
          >
            <Box
              position={"relative"}
              left="-50%"
              top="50%"
              sx={{ transform: "translateY(-50%)" }}
            >
              <Chip
                label={`Week ${scheduleItem.weekAndDay.week}`}
                color="primary"
                size="small"
              />
            </Box>
          </Box>
        )}
      </WorkoutRow>
      {scheduleItem.workout && (
        <WorkoutViewDialog
          workout={scheduleItem.workout}
          canEdit={canEdit}
          onEdit={onClickEdit}
          disabledEditTooltipText="This plan is published"
          close={() => setIsPeekingWorkout(false)}
          isOpen={isPeekingWorkout}
          weekIndex={scheduleItem.weekAndDay.week - 1}
          dayIndex={scheduleItem.weekAndDay.dayOfWeek - 1}
        />
      )}
    </>
  );
};
