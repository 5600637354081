import React from "react";

import { Add, KeyboardBackspace, Link } from "@mui/icons-material";
import AccessTime from "@mui/icons-material/AccessTime";
import {
  Box,
  Container,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { theme } from "../../../theme/theme";
import {
  ActionButtonsContainer,
  SuccessActionButton,
} from "../../common/ActionButtons";
import { ConfirmDialog } from "../../common/Dialog";
import { InlineInputEdit } from "../../common/InlineInputEdit";
import { PageTemplate } from "../../common/PageTemplate";
import { SnackbarAlert } from "../../common/SnackbarAlert";
import { EmptyWorkout } from "./EmptyWorkout";
import { ExerciseAutocomplete } from "./ExerciseAutocomplete/ExerciseAutocomplete";
import { ExerciseBlock } from "./ExerciseBlock";
import { useWorkoutBuilder } from "./useWorkoutBuilder";
import { calculateWorkoutDuration, formatWorkoutDuration } from "./utils";

interface Props extends ReturnType<typeof useWorkoutBuilder> {}

export const WorkoutBuilder: React.FC<Props> = ({
  expandedWorkout,
  saveWorkoutAndWorkoutDescription,
  saveWorkoutAndWorkoutName,
  isLoadingUpdateWorkout,
  isShowingUpdateErrorMessage,
  hideUpdateErrorMessage,
  exerciseSearchResultsResource,
  searchQuery,
  setSearchQuery,
  getNextPage,
  addNewExercise,
  isShowingWorkoutBuilderError,
  hideWorkoutBuilderError,
  deleteExerciseEntry,
  duplicateSetInEntry,
  updateSetInEntry,
  deleteSetInEntry,
  saveWorkoutDialog,
  updateExerciseTypeOfEntry,
  linkExerciseBlockWithNext,
  unlinkExerciseEntrysAtIndexForBlock,
  onDragEnd,
  exerciseInsertIndex,
  setExerciseInsertIndex,
  hasWorkoutChanged,
  onPressBack,
  weekAndDay,
  workoutPlanName,
  updateNotesInEntry,
  openLeaveBuilderDialog,
  closeLeaveBuilderDialog,
  isLeaveBuilderDialogOpen,
  updateVideoInExerciseEntry,
  saveWorkoutSectionsNameAndDescriptionThenNavigate,
  workoutDurationInMinutes,
}) => {
  return (
    <>
      <PageTemplate
        fullWidthHeader={
          <Container
            maxWidth="lg"
            sx={{
              position: "sticky",
              top: 0,
              zIndex: 1299,
              bgcolor: "background.default",
            }}
          >
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            >
              <Box display="flex" alignItems="center">
                <IconButton
                  onClick={
                    hasWorkoutChanged ? openLeaveBuilderDialog : onPressBack
                  }
                >
                  <KeyboardBackspace fontSize="small" />
                </IconButton>
                {!!workoutPlanName && (
                  <Typography variant="body2" color="GrayText">
                    {workoutPlanName}
                  </Typography>
                )}
              </Box>
              <Box>
                {!!weekAndDay && (
                  <Typography variant="body2" fontWeight={700}>
                    {`Week ${weekAndDay.week} Day ${weekAndDay.dayOfWeek}`}
                  </Typography>
                )}
              </Box>
            </Box>
            <Box
              width={"100%"}
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
            >
              <Box display="flex" flexDirection="column">
                <InlineInputEdit
                  variant="h4"
                  initialValue={expandedWorkout.name}
                  handleSubmitText={saveWorkoutAndWorkoutName}
                  isInputLoading={isLoadingUpdateWorkout}
                  tooltipText="Edit workout name"
                />
                <InlineInputEdit
                  variant="subtitle1"
                  initialValue={expandedWorkout.description}
                  placeholderText="Add a description"
                  typographyProps={{ color: "textSecondary" }}
                  textFieldProps={{ sx: { minWidth: "30%" } }}
                  handleSubmitText={saveWorkoutAndWorkoutDescription}
                  isInputLoading={isLoadingUpdateWorkout}
                  tooltipText="Edit workout description"
                />
              </Box>
              <ActionButtonsContainer
                flexDirection="column"
                justifyContent="space-around"
              >
                <Tooltip title="Guideline time to complete workout including exercise setup time">
                  <Box
                    gap={1}
                    display="flex"
                    flexDirection="row"
                    alignItems="center"
                  >
                    <Typography
                      textAlign="right"
                      variant="body2"
                      sx={({ palette }) => ({
                        color: palette.text.secondary,
                      })}
                    >
                      {`Estimated time:
                    ${formatWorkoutDuration(workoutDurationInMinutes)}`}
                    </Typography>
                    <AccessTime
                      fontSize="small"
                      sx={({ palette }) => ({
                        color: palette.text.secondary,
                      })}
                    />
                  </Box>
                </Tooltip>

                <SuccessActionButton
                  isActive={hasWorkoutChanged}
                  onClick={saveWorkoutDialog.openDialog}
                  text={"Save Workout"}
                  inactiveText={"Changes Saved"}
                  label={
                    isLoadingUpdateWorkout
                      ? "Saving..."
                      : "Contains unsaved changes"
                  }
                  inactiveLabel="No new changes"
                />
              </ActionButtonsContainer>
            </Box>
          </Container>
        }
      >
        {!expandedWorkout.sections[0].blocks?.length ? (
          <EmptyWorkout onClickAddExercise={() => setExerciseInsertIndex(0)} />
        ) : (
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="blocks" type="block">
              {(droppableProps) => (
                <Box
                  {...droppableProps.droppableProps}
                  ref={droppableProps.innerRef}
                  flexDirection="column"
                  display="flex"
                  py={2}
                >
                  {expandedWorkout.sections[0].blocks?.map(
                    (block, blockIndex, blockArray) => {
                      const cumulativeWorkoutDuration =
                        calculateWorkoutDuration([
                          {
                            id: "",
                            blocks: blockArray.slice(0, blockIndex + 1),
                          },
                        ]);
                      return (
                        <Draggable
                          draggableId={block.id}
                          key={block.id}
                          index={blockIndex}
                        >
                          {(draggableProps, snap) => (
                            <Box
                              {...draggableProps.draggableProps}
                              ref={draggableProps.innerRef}
                            >
                              <ExerciseBlock
                                exerciseBlock={block}
                                deleteExerciseEntry={(entryIndex) =>
                                  deleteExerciseEntry(blockIndex, entryIndex)
                                }
                                duplicateSetInEntry={(setIndex) =>
                                  duplicateSetInEntry(blockIndex, setIndex)
                                }
                                deleteSetInEntry={(setIndex) =>
                                  deleteSetInEntry(blockIndex, setIndex)
                                }
                                updateSetInEntry={(entryIndex, set) =>
                                  updateSetInEntry(blockIndex, entryIndex, set)
                                }
                                updateExerciseType={(entryIndex, parameter) =>
                                  updateExerciseTypeOfEntry(
                                    blockIndex,
                                    entryIndex,
                                    parameter
                                  )
                                }
                                splitAfterEntryWithIndex={(entryIndex) =>
                                  unlinkExerciseEntrysAtIndexForBlock(
                                    blockIndex,
                                    entryIndex
                                  )
                                }
                                updateNotesInEntry={(entryIndex, notes) =>
                                  updateNotesInEntry(
                                    blockIndex,
                                    entryIndex,
                                    notes
                                  )
                                }
                                updateVideoInExerciseEntry={(
                                  entryIndex,
                                  videoId
                                ) =>
                                  updateVideoInExerciseEntry(
                                    blockIndex,
                                    entryIndex,
                                    videoId
                                  )
                                }
                                dragBlockHandleProps={
                                  draggableProps.dragHandleProps
                                }
                                saveWorkoutSectionsNameAndDescriptionThenNavigate={
                                  saveWorkoutSectionsNameAndDescriptionThenNavigate
                                }
                              />
                              <Divider
                                sx={{
                                  opacity: snap.isDragging ? 0 : 0.4,
                                  transition: "opacity 0.5s ease",
                                  ":hover": {
                                    opacity: 1,
                                    transition: "opacity 0.5s ease",
                                  },
                                  py: 1,
                                }}
                              >
                                {blockIndex !==
                                  expandedWorkout.sections?.[0]?.blocks.length -
                                    1 && (
                                  <Tooltip title={"Create superset"}>
                                    <IconButton
                                      onClick={() =>
                                        linkExerciseBlockWithNext(blockIndex)
                                      }
                                    >
                                      <Link />
                                    </IconButton>
                                  </Tooltip>
                                )}
                                <Tooltip title={"Add exercise here"}>
                                  <IconButton
                                    onClick={() => {
                                      setExerciseInsertIndex(blockIndex + 1);
                                    }}
                                  >
                                    <Add
                                      style={{
                                        background: theme.palette.primary.main,
                                        color: "#fff",
                                        borderRadius: "50%",
                                      }}
                                    />
                                  </IconButton>
                                </Tooltip>
                                <Tooltip
                                  title={`Est. ${formatWorkoutDuration(
                                    cumulativeWorkoutDuration
                                  )} so far`}
                                >
                                  <IconButton>
                                    <AccessTime />
                                  </IconButton>
                                </Tooltip>
                              </Divider>
                            </Box>
                          )}
                        </Draggable>
                      );
                    }
                  )}
                  {droppableProps.placeholder}
                </Box>
              )}
            </Droppable>
          </DragDropContext>
        )}
        <Dialog
          open={exerciseInsertIndex !== null}
          onClose={() => setExerciseInsertIndex(null)}
          fullWidth
          disableRestoreFocus
        >
          <DialogTitle>{"Add an exercise"}</DialogTitle>
          <DialogContent>
            <DialogContentText>
              {"Start typing an exercise name below"}
            </DialogContentText>
            <Box py={1}>
              <ExerciseAutocomplete
                options={exerciseSearchResultsResource.data || []}
                isLoading={exerciseSearchResultsResource.isLoading}
                getNextPage={getNextPage}
                searchQuery={searchQuery}
                setSearchQuery={setSearchQuery}
                onSelectExercise={(exercise) => {
                  exercise && addNewExercise(exercise);
                  setExerciseInsertIndex(null);
                }}
              />
            </Box>
          </DialogContent>
        </Dialog>
      </PageTemplate>
      <ConfirmDialog {...saveWorkoutDialog.props} />
      <SnackbarAlert
        close={hideUpdateErrorMessage}
        isOpen={isShowingUpdateErrorMessage}
        message="Something went wrong whilst updating the workout. Please try again later."
        severity="error"
      />
      <SnackbarAlert
        isOpen={isShowingWorkoutBuilderError}
        close={hideWorkoutBuilderError}
        severity="warning"
        message="Oops! Something went wrong with the workout builder"
      />
      <ConfirmDialog
        title={"Are you sure you want to leave?"}
        subtitle={"You have unsaved changes"}
        isOpen={isLeaveBuilderDialogOpen}
        onClose={closeLeaveBuilderDialog}
        onClickConfirm={onPressBack}
        onClickCancel={closeLeaveBuilderDialog}
      />
    </>
  );
};
