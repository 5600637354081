export const roundUpToNearestN = (input: number, n: number): number => {
  return Math.ceil(input / n) * n;
};

export const roundToNearestN = (input: number, n: number): number => {
  if (n === 0) {
    throw new Error("Cannot round to a multiple of 0.");
  }
  return Math.round(input / n) * n;
};
